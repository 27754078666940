(function( $ ) {
  window.addEventListener('DOMContentLoaded', function () {
    console.log('JS is working - remove it when it was tested');
    let primary = document.querySelector('#content');
    let height = primary.offsetHeight;

    document.querySelector('#bg-before').style.height = height + "px";
    document.querySelector('#bg-after').style.height = height + "px";
  });

})( jQuery );

function setCookie(cname, cvalue, exdays) {
  const currentDate = new Date();
  currentDate.setTime(currentDate.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires="+currentDate.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  const name = cname + "=";
  const cookies = document.cookie.split(';');
  for(let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}
